import { makeApiCall } from "../makeApiCall";

const mastersServices = {
  //Buyer, Seller On-boaring
  partyMaster: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/admin/createAdminOrg`,
      data: data,
    }),
  listofparties: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/admin/fetchAdminOrg`,
    }),
  updateparties: (data: any, id: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/admin/updateAdminOrg`,
      data,
      params: { id },
    }),
    deleteAdminOrgById: (id: any): Promise<any> =>
      makeApiCall<any>({
        method: "delete",
        url: `/admin/deleteAdminOrgById`,
        params: { id },
      }),
  addMaterialCategory: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/admin/createMaterialCategory`,
      data: data,
    }),
  getMaterialCategories: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/admin/fetchMaterialCategory`,
    }),
  updateMaterialCategory: (data: any, id: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/admin/updateMaterialCategory`,
      data,
      params: { id },
    }),
  removeMaterialCategory: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/admin/deleteMaterialCategoryById`,
      params: { id },
    }),
  addUOM: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/admin/createUom`,
      data,
    }),
  getUOMs: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/admin/fetchUom`,
    }),
  updateUOM: (data: any, id: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/admin/updateUom`,
      data,
      params: { id },
    }),
  removeUOM: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/admin/deleteUomById`,
      params: { id },
    }),
  getAdminMaterial: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/admin/fetchAdminMaterial`,
    }),
  addAdminMaterial: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/admin/createAdminMaterial`,
      data: data,
    }),
  updateAdminMaterial: (data: any, id: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/admin/updateAdminMaterial`,
      data,
      params: { id },
    }),
  removeAdminMaterial: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/admin/deleteAdminMaterialById`,
      params: { id },
    }),
  getDocumentType: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/admin/fetchDocumentType`,
    }),
  addDocumentType: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/admin/createDocumentType`,
      data: data,
    }),
  updateDocumentType: (data: any, id: any): Promise<any> =>
    makeApiCall<any>({
      method: "put",
      url: `/admin/updateDocumentType`,
      data,
      params: { id },
    }),
  removeDocumentType: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "delete",
      url: `/admin/deleteDocumentTypeById`,
      params: { id },
    }),
  getCurrentUser: (): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/users/currentUser`,
    }),
  fetchAdminOrgById: (id: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: "/admin/fetchAdminOrgById",
      params: { id },
    }),
  verifyLegalDetails: (data: any): Promise<any> =>
    makeApiCall<any>({
      method: "post",
      url: `/verifyLegalDetails`,
      data: data,
    }),
};

export default mastersServices;
