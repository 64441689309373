import { TooltipComponent } from "../../components/Component";
import React from "react";
import { useFormContext } from "react-hook-form";
import { FieldErrors, FieldValues } from "react-hook-form";
import { Tooltip, Input, Form } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';

interface FormInputProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  column?: string;
  pattern?: RegExp;
  customValidation?: { validate: (value: string) => boolean | string };
  defaultValue?: string | number;
  disabled?: boolean;
  tooltipText?: string;
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({
  name,
  label,
  type = "text",
  required = false,
  minLength,
  maxLength,
  placeholder,
  column,
  pattern,
  customValidation,
  defaultValue,
  disabled,
  tooltipText,
  onInput
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorMessage = getErrorMessage(errors, name);
  return (
    <div className={column}>
      <div className="form-group">
        <div className="form-label-wrapper">
          <label className="form-label" htmlFor={name}>
            {label}
            {required && <span className="mandatory">*</span>}
          </label>
          {tooltipText && (
            <TooltipComponent
              iconClass="card-hint"
              icon="help-fill"
              direction="left"
              id={`${name}-tooltip`}
              text={tooltipText}
              containerClassName={''}
            />
          )}
        </div>
        <div className="form-control-wrap">
          <input
            type={type}
            id={name}
            placeholder={placeholder}
            className="form-control"
            {...register(name, {
              required: required ? "This field is required" : undefined,
              minLength: minLength
                ? {
                  value: minLength,
                  message: `Must be at least ${minLength} characters`,
                }
                : undefined,
              maxLength: maxLength
                ? {
                  value: maxLength,
                  message: `Cannot be more than ${maxLength} characters`,
                }
                : undefined,
              pattern: pattern
                ? { value: pattern, message: "Invalid format" }
                : undefined,
              validate: customValidation
                ? customValidation.validate
                : undefined,
            })}
            onInput={onInput}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        </div>
        {errorMessage && <p className="mandatory">{errorMessage}</p>}
      </div>
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean); 
  let currentError: any = errors;

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part];
    } else {
      return undefined;
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined;
}

export default FormInput;
