import classNames from 'classnames';
import React, { FC } from 'react';

interface IconProps {
  name: string;
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  [key: string]: any; // For any additional props
}

const Icon: FC<IconProps> = ({ name, id, className, style, ...props }) => {
  const iconClass = classNames({
    [`${className}`]: className,
    icon: true,
    ni: true,
    [`ni-${name}`]: true,
  });

  return <em className={iconClass} id={id} style={style} {...props}></em>;
};

export default Icon;
