import React from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
    Block,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    BlockBetween,
    Button,
    Icon
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
const Header_Buyer: React.FC = (props: any) => {
    return (
        <>
            <div className="steps clearfix">
                <ul>
                    <li className={props.current >= 1 ? "first done" : "first"}>
                        <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
                            <span className="number">01</span> <h5>General Information</h5>
                        </a>
                    </li>
                    <li className={props.current >= 2 ? "done" : ""}>
                        <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">02</span> <h5>Legal & Bank Information </h5>
                        </a>
                    </li>
                    <li className={props.current >= 3 ? "done" : ""}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">03</span> <h5>Contact Information</h5>
                        </a>
                    </li>
                    {/* <li className={props.current === 4 ? "last done" : "last"}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            <span className="current-info audible">current step: </span>
                            <span className="number">04</span> <h5>Bank Account Details</h5>
                        </a>
                    </li> */}
                    {/* <li className={props.current === 5 ? "last done" : "last"}>
                        <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
                            
                            <span className="number">05</span> <h5>Documents</h5>
                        </a>
                    </li> */}
                </ul>
            </div>
        </>
    );
};
export default Header_Buyer;
