import React, { useState, useEffect, useCallback } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Button,
  Icon,
} from "../../components/Component";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import Swal from "sweetalert2";
import OrganizationTypes from "./OrgTypes";
import mastersServices from "../../api/masters/mastersService";

const ListOfParties: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<OrganizationTypes[]>();

  // useEffect(() => {
  //     const updatedData = rowData?.map((item, index) => ({
  //         ...item,
  //         index: index + 1,
  //     }));
  //     setRowData(updatedData);
  // }, []);

  const columns: TableColumn<OrganizationTypes>[] = [
    // {
    //     name: "#",
    //     selector: row => row.index!,
    //     sortable: true,
    // },
    {
      name: "Organisation",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Registered As",
      // selector: row => row.type,
      sortable: true,
      width: "150px",
      cell: (row: any) => (
        <Badge
          size="xs"
          color={"outline-primary"}
          pill
          className="badge-xs badge-dim"
        >
          {row.type}
        </Badge>
      ),
    },
    {
      name: "GSTIN",
      selector: (row) => row.gstin,
      sortable: true,
      width: "200px",
    },
    {
      name: "Contact Name",
      selector: (row: any) => row.contactName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.adminEmail,
      sortable: true,
      width: "200px",
    },
    {
      name: "State",
      selector: (row) => row.companyAddress.state.stateName,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.companyAddress.city,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdDate).format("DD MMMM YYYY"),
      sortable: true,
      width: "150px",
    },
    {
      name: "",
      width: "90px",
      sortable: false,
      cell: (row: any) => (
        <DropdownTrans
          row={row}
          onEdit={() => handleEdit(row)}
          onRemove={() => handleRemove(row)}
        />
      ),
    },
  ];

  const handleEdit = (row: OrganizationTypes) => {
    //    alert('Hello')
    console.log(row);
    navigate("/party-details", { state: { row } });
  };

  const handleRemove = (row: OrganizationTypes) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrg(row.orgID);
        console.log("Removing row:", row);
      }
    });
  };

  const deleteOrg = async(id: any) => {
    const { status, message } =await mastersServices.deleteAdminOrgById(id);
    if (status) {
        Swal.fire("Deleted!", "Deleted Organisation", "success");
      await getAllParties(); // Assuming fetchData is a function to fetch updated supplier data
    } else {
      Swal.fire("Error", message, "error");
    }
};

  const getAllParties = useCallback(async () => {
    try {
      const parties = await mastersServices.listofparties();
      if (parties.status && parties.data.length > 0) {
        setRowData(parties.data);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);
  useEffect(() => {
    getAllParties();
  }, []);
  return (
    <React.Fragment>
      <Head title="List of parties" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">
                <span>List of parties</span>
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => {
                  navigate("/party-onboarding");
                }}
              >
                <Icon name="plus"></Icon>
                <span>Add New Party</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <DataTable
              columns={columns}
              data={rowData || []}
              pagination
              striped
              //highlightOnHover
              dense
              className="custom-compact-rows"
            />
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
interface DropdownTransProps {
  row: OrganizationTypes;
  onEdit: () => void;
  onRemove: () => void;
}

const DropdownTrans: React.FC<DropdownTransProps> = ({
  row,
  onEdit,
  onRemove,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
      >
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul
          className="link-list-plain"
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onEdit();
              }}
            >
              <Icon name="icon ni ni-edit"></Icon>
              View/Edit
            </DropdownItem>
          </li>
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onRemove();
              }}
            >
              <Icon name="icon ni ni-na"></Icon>
              Remove
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default ListOfParties;
