import React from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";
  
  const SupplierOnboard: React.FC = (props) => {
  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <Block>
          <PreviewCard className={''} bodyClass="card-inner-xl">
           
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default SupplierOnboard;
