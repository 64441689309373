import React, { ReactNode, FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";

interface BlockProps {
  className?: string;
  size?: string;
  children?: ReactNode;
}

interface BlockHeadProps extends BlockProps {
  wide?: boolean;
}

interface BlockTitleProps extends BlockProps {
  page?: boolean;
  tag?: any; // You can specify a more specific type based on what you expect
}

interface BlockImageProps {
  classNames?: string;
  children?: ReactNode;
}

interface BackToProps extends BlockProps {
  link: string;
  icon: string;
  onClick?: () => void;
}

export const Block: FC<BlockProps> = ({ className, size, children }) => {
  const blockClass = classNames({
    "nk-block": true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{children}</div>;
};

export const BlockContent: FC<BlockProps> = ({ className, children }) => {
  const blockContentClass = classNames({
    "nk-block-content": true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{children}</div>;
};

export const BlockBetween: FC<BlockProps> = ({ className, size, children }) => {
  return (
    <div className={`${size ? `nk-block-between-${size}` : "nk-block-between"} ${className || ""}`}>
      {children}
    </div>
  );
};

export const BlockHead: FC<BlockHeadProps> = ({ className, size, wide, children }) => {
  const blockHeadClass = classNames({
    "nk-block-head": true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{children}</div>;
};

export const BlockHeadContent: FC<BlockProps> = ({ className, children }) => {
  return <div className={`nk-block-head-content${className ? " " + className : ""}`}>{children}</div>;
};

export const BlockTitle: FC<BlockTitleProps> = ({ className, page, children, tag }) => {
  const classes = `nk-block-title ${page ? "page-title" : "title"}${className ? " " + className : ""}`;
  const Tag = tag || "h3"; // Defaults to 'h3' if no tag is provided
  return (
    <Tag className={classes}>{children}</Tag>
  );
};

export const BlockDes: FC<BlockProps> = ({ className, children }) => {
  const classes = `nk-block-des${className ? " " + className : ""}`;
  return <div className={classes}>{children}</div>;
};

export const BlockHeadSub: FC<BlockProps> = ({ className, children }) => {
  return (
    <div className={`nk-block-head-sub ${className || ""}`}>
      <span>{children}</span>
    </div>
  );
};

export const BlockImage: FC<BlockImageProps> = ({ classNames, children }) => {
  return <div className={`nk-block-image ${classNames || ""}`}>{children}</div>;
};

export const BackTo: FC<BackToProps> = ({ className, link, icon, onClick, children }) => {
  const classes = `back-to${className ? " " + className : ""}`;
  return (
    <div className="nk-block-head-sub" onClick={onClick}>
      <Link className={classes} to={process.env.PUBLIC_URL + link}>
        <Icon name={icon} />
        <span>{children}</span>
      </Link>
    </div>
  );
};
