import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

export const ProtectedRoute = () => {
  const isAuthenticated = Cookies.get('accessToken');

  if (!isAuthenticated) {
    // Redirect them to the login page, but save the current location they were trying to go to
    return <Navigate to={`${process.env.PUBLIC_URL}/`} replace />;
  }

  return <Outlet />;
};