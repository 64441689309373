import { makeApiCall } from "../makeApiCall";

const misc = {
  getCountries: (): Promise<any> => makeApiCall<any>({ method: 'get', url: '/misc/countries' }),
  getStatesByCountry: (countryId: number): Promise<any> => makeApiCall<any>({ 
    method: 'get', 
    url: `/misc/stateByCountry`, 
    params: { countryId } 
  }),
  getDistrictsByState: (stateId: number): Promise<any> => makeApiCall<any>({ 
    method: 'get', 
    url: `/misc/districtByState`, 
    params: { stateId }
  }),
  getOygTypes: (): Promise<any> => makeApiCall<any>({ 
    method: 'get', 
    url: `admin/fetchCompanyTypes`, 
  }),
  onboardValidations: (data:any): Promise<any> => makeApiCall<any>({ 
    method: 'post', 
    url: `/admin/fetchAdminOrgValidation`, 
    data: data
  }),
  getIndustryTypes: (): Promise<any> => makeApiCall<any>({ 
    method: 'get', 
    url: `misc/allIndustryTypes`, 
  }),
  getContructionsMaterialTypes: (): Promise<any> => makeApiCall<any>({ 
    method: 'get', 
    url: `/misc/constructionMaterials`, 
  }), 
};


export default misc;
