import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {'Content-Type': 'application/json'},
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data && config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = "/";
      return error;
    }
    if(error.response.status === 500){
      return error?.response
    }
    if (error.response.status === 409) {
      if (error?.response?.data?.errors) {
        return {
          data: { status: false, errors: error?.response?.data?.errors },
        };
      }
      return {
        data: { status: false, message: error?.response?.data?.message },
      };
    }
    return error;
  }
);
export default axiosInstance;