import React from "react";
import {
  useFormContext,
  Controller,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
interface FormDatePickerProps {
  name: string;
  label: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  defaultValue?: Date;
  disabled?: boolean;
  placeholder?: string;
  customValidation?: { validate: (selectedDate: Date) => boolean | string };
  scrollableYearDropdown?: boolean;
  showYearDropdown?: boolean;
  onChange?: (date: Date) => void;
  dateFormat?: string;
}

const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  label,
  minDate,
  maxDate,
  required,
  defaultValue,
  disabled,
  placeholder,
  customValidation,
  scrollableYearDropdown,
  showYearDropdown,
  dateFormat = "DD-MMM-YYYY",
  onChange,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = getErrorMessage(errors, name);
  const disabledDate = (current: any) => {
    const minDay = minDate ? dayjs(minDate) : null;
    const maxDay = maxDate ? dayjs(maxDate) : null;

    if (minDay && current && current.isBefore(minDay, "day")) {
      return true;
    }

    if (maxDay && current && current.isAfter(maxDay, "day")) {
      return true;
    }

    return false;
  };
  return (
    <div className="form-group">
      <label className="form-label" htmlFor={name}>
        {label}
        {required ? <span className="mandatory">*</span> : ""}
      </label>
      <div className="form-control-wrap">
        <Controller
          name={name}
          control={control}
          rules={{
            required: required ? "This field is required" : undefined,
            validate: customValidation ? customValidation.validate : undefined,
          }}
          render={({ field }) => (
            <DatePicker
              id={name}
              className="form-control datepicker"
              value={field.value}
              onChange={(date: any) => {
                field.onChange(date);
                if (onChange) {
                  onChange(date);
                }
              }}
              format={dateFormat}
              disabled={disabled}
              placeholder={placeholder}
              disabledDate={disabledDate}
              showTime={false}
              size="small"
              variant="filled"
              inputReadOnly={true}
            />
          )}
        />
      </div>
      {errorMessage && <p className="mandatory">{errorMessage}</p>}
    </div>
  );
};

function getErrorMessage<T extends FieldValues>(
  errors: FieldErrors<T>,
  fieldName: string
): string | undefined {
  const parts = fieldName.split(/[\[\].]+/).filter(Boolean); // This splits on brackets and dots, and removes empty strings
  let currentError: any = errors; // Start with the whole errors object

  for (const part of parts) {
    if (currentError && typeof currentError === "object") {
      currentError = currentError[part]; // Navigate deeper into the errors object
    } else {
      return undefined; // No error found for this part
    }
  }

  if (
    currentError &&
    "message" in currentError &&
    typeof currentError.message === "string"
  ) {
    return currentError.message;
  }

  return undefined; // No message found
}

export default FormDatePicker;
