import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";

// Importing SCSS files
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import "./App.css"

// Importing the reportWebVitals function
import reportWebVitals from "./reportWebVitals";

// Using the new ReactDOM client to create and manage the root
const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Optional: Measurement of performance in your app
// You can pass a function to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
