import axiosInstance from '../axiosInstance';

import { LoginCredentials, AuthResponse } from '../types/auth/authTypes';

export const login = async (credentials: LoginCredentials): Promise<any> => {
  try {
    const response = await axiosInstance.post<AuthResponse>('/auth/login', credentials);
    return response.data;
  } catch (error:any) {
    throw new Error('Login failed: ' + error.message);
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post<AuthResponse>('/auth/logout',);
    return response.data;
  } catch (error:any) {
    throw new Error('Logout failed: ' + error.message);
  }
};
 


