import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  TooltipComponent,
} from "../../../components/Component";
import {  Row, Col } from "reactstrap";
import {  useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormDatePicker from "../../form-components/DatePicker";
import {  Spinner, Alert } from "reactstrap";
import misc from "../../../api/misc/miscService";
import { ErrorMessage } from "./ErrorMessage";
import mastersServices from "../../../api/masters/mastersService";
import Swal from "sweetalert2";

const LegalInformation_Buyer: React.FC = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertInfo, setAlertInfo] = useState<{
    type: string;
    message: string;
    errors: any;
  }>({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [countdown, setCountdown] = useState<number>(10);

  const { handleSubmit ,watch,setValue} = useFormContext();
   const files = watch("files");

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (alertInfo) {
      setCountdown(10);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            setAlertInfo({ type: "", message: "", errors: [] });
            clearInterval(timer);
            return 10; // Reset the countdown
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [alertInfo]);

  const validateOnboard = async (data: any) => {
    try {
      let validation = await misc.onboardValidations({
        cin: data.cin,
        pan: data.pan,
        gstin: data.gstin,
      });
      let errorMessage: ErrorMessage = validation;
      if (errorMessage?.status) {
        const errorsArray: string[] = [];
        for (const key in errorMessage.errors) {
          if (errorMessage.errors[key].status) {
            errorsArray.push(errorMessage.errors[key].message);
          }
        }
        setAlertInfo({
          type: "error",
          message: errorsArray.join(", "),
          errors: errorsArray,
        });

        return errorsArray;
      }
      return null;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  };

  const validateSandbox = async (data: any) => {
    try {
      const response = await mastersServices.verifyLegalDetails({
        cin: data.cin,
        bankaccountNumber: data.bankaccountNumber,
        ifscCode: data.ifscCode,
        gstin: data.gstin,
        orgName: data.orgName,
      });
      console.log(response?.data);

      let SandBoxerrorMessage: any = response;
      if (SandBoxerrorMessage?.status) {
        const errorsArray: string[] = [];
        for (const key in SandBoxerrorMessage.errors) {
          if (SandBoxerrorMessage.errors[key].status) {
            errorsArray.push(SandBoxerrorMessage.errors[key].message);
          }
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorsArray.join("\n"),
        });
        // setAlertInfo({
        //   type: "error",
        //   message: "",
        //   errors: errorsArray,
        // });
        return errorsArray;
      }
      return null;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  };

  const submitForm = async (data: any) => {
    console.log("On submit all data----", data);
    setLoading(true);
    try {
      const onboardErrors: any = await validateOnboard(data);
      if (onboardErrors) {
        return;
      }
      let sandboxErrors: any = null;
      //sandboxErrors= await validateSandbox(data);

      if (sandboxErrors) {
        setAlertInfo(() => ({
          type: "error",
          message: "",
          errors: sandboxErrors,
        }));
        setLoading(false);
        return;
      }
      setAlertInfo({
        type: "",
        message: "",
        errors: [],
      });
      setLoading(false);
      props.next();
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };

  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <h5>Legal Information</h5>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="cin"
              label="CIN"
              required={true}
              type="text"
              minLength={2}
              maxLength={30}
              placeholder="Enter CIN"
              pattern={/^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/}
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name="incorporationDate"
              label="Incorporation Date"
              showYearDropdown={true}
              scrollableYearDropdown={true}
              maxDate={new Date()} // Today's date as the minimum date
              //maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Maximum date set to one year from today
              // required={true}
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormInput
              name="pan"
              label="PAN"
              required={true}
              type="text"
              placeholder="Enter PAN"
              pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="gstin"
              label="GSTIN"
              required={true}
              type="text"
              placeholder="Enter GSTIN"
              pattern={
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
              }
            />
          </Col>
          <h5>Bank Information</h5>
          <Col lg="4">
            <FormInput
              name="accountName"
              label="Account Holder Name"
              required={true}
              type="text"
              placeholder="Enter Account Name"
              // minLength={10}
              // maxLength={20}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankaccountNumber"
              label="Account Number"
              required={true}
              type="number"
              placeholder="Bank Account Number"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="bankName"
              label="Bank Name"
              required={true}
              type="text"
              placeholder="Enter Bank Name"
              // minLength={5}
              // maxLength={30}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormInput
              name="branchName"
              label="Branch Name"
              required={true}
              type="text"
              placeholder="Enter Branch Name"
              // minLength={3}
              // maxLength={30}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="ifcCode"
              label="IFSC Code"
              required={true}
              type="text"
              placeholder="Enter IFSC Code"
              // minLength={3}
              // maxLength={15}
            />
          </Col>
          <Col lg="4">
          {files && files[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"file"}>
                    Upload Cancelled Cheque <span className="mandatory">*</span>
                  </label>
                </div>
                <div className="form-control-wrap d-flex align-items-center justify-content-between border p-1" style={{borderRadius: "3px"}}>
                  <span>{files[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("files", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${files && files[0]?.name ? "none" : "block"}`,
              }}
            >
              <FormInput
                name="files"
                label="Upload Cancelled Cheque"
                type="file"
                required={files && files[0]?.name ? false : true}
                placeholder="Upload Cancelled Cheque"
              />
            </div>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                {loading ? <Spinner size="sm" color="light" /> : "Next"}
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default LegalInformation_Buyer;
