import React, { useState, FC } from "react";

// Define the interface for the component props
interface InputSwitchProps {
  label?: string;
  id: string;
  checked: boolean;
}

const InputSwitch: FC<InputSwitchProps> = ({ label, id, checked }) => {
  // useState should explicitly start with a boolean from the 'checked' prop
  const [inputCheck, setCheck] = useState<boolean>(checked);

  const handleClick = () => {
    setCheck(prevCheck => !prevCheck);
  };

  return (
    <>
      <input
        type="checkbox"
        className="custom-control-input"
        defaultChecked={inputCheck}
        onClick={handleClick}
        id={id}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </>
  );
};

export default InputSwitch;
