import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col } from 'reactstrap';

interface FormCheckboxProps {
  name: string;
  label?: string;
  optionLabel: string;
  defaultChecked?: boolean;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  validation?: any;  // Custom validation rules
  size?: any,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; 
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  label,
  optionLabel,
  defaultChecked = false,
  required = false,
  disabled = false,
  className = "",
  validation,
  size,
  onChange
}) => {
  const { register, formState: { errors } } = useFormContext();
  const error = errors[name];

  return (
    <>
      <div className="preview-block">
        <span className="preview-title overline-title">{label}</span>
        </div><div className="custom-control custom-checkbox">
        <div className={`custom-control custom-checkbox ${ size=='small'?'custom-control-sm': size=='medium'?'custom-control-md':size=='large'?'custom-control-lg':''}`}>
          <input
            type="checkbox"
            className="custom-control-input"
            id={`${name}-switch`}
            defaultChecked={defaultChecked}
            disabled={disabled}
            {...register(name, {
              ...validation
            })}
            onChange = {onChange}
          />
          <label className="custom-control-label" htmlFor={`${name}-switch`}>
            {optionLabel}
          </label>
        </div>
        {error && <div className="invalid-feedback">{error.message as string}</div>}
      </div>
    </>
  );
};

export default FormCheckbox;


//Example Usage including custom validation

// import { useForm, FormProvider } from 'react-hook-form';

// const MyForm = () => {
//   const methods = useForm({
//     mode: 'onChange',
//     defaultValues: {
//       featureEnabled: false,
//       featureEnabled2: false,
//       featureEnabled3: false
//     }
//   });
  
//   const { handleSubmit, watch, setError, clearErrors } = methods;
//   const values = watch();  // This will subscribe to all field values

//   const onSubmit = data => {
//     const selectedCount = Object.keys(data).filter(key => data[key]).length;
//     if (selectedCount < 2) {
//       setError('checkboxGroup', {
//         type: 'manual',
//         message: 'At least two options must be selected!'
//       });
//     } else {
//       clearErrors('checkboxGroup');
//       console.log('Data submitted: ', data);
//     }
//   };

//   return (
//     <FormProvider {...methods}>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <Row>
//           <FormCheckbox
//             name="featureEnabled"
//             label="Feature 1"
//             optionLabel="Enable Feature 1"
//           />
//           <FormCheckbox
//             name="featureEnabled2"
//             label="Feature 2"
//             optionLabel="Enable Feature 2"
//           />
//           <FormCheckbox
//             name="featureEnabled3"
//             label="Feature 3"
//             optionLabel="Enable Feature 3"
//           />
//         </Row>
//         <div className="invalid-feedback">
//           {methods.formState.errors.checkboxGroup?.message}
//         </div>
//         <button type="submit">Submit</button>
//       </form>
//     </FormProvider>
//   );
// };

