import React, { useEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { Icon } from "../../../components/Component";

interface CropperComponentProps {
  modal: boolean;
  toggle: () => void;
  onSave: (croppedImage: string) => void;
}

const CropperComponent: React.FC<CropperComponentProps> = ({
  modal,
  toggle,
  onSave,
}) => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [image, setImage] = useState<string>("");

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      const croppedDataUrl = cropper.getCroppedCanvas().toDataURL();
      onSave(croppedDataUrl);
    }
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (file.size > maxSize) {
        alert(`File size exceeds ${maxSize / (1024 * 1024)}MB`);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const uploadImage = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = handleFileChange as any;
    input.click();
  };
  useEffect(() => {
    if (modal) {
      uploadImage();
    }
  }, [modal]);
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Crop Image</ModalHeader>
      <ModalBody>
        <Cropper
          src={image}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={16 / 9}
          guides={true}
          ref={cropperRef}
        />
          <Row className="justify-content-center gy-4" style={{ marginTop: "10px" }}>
            <Button color="btn-dim btn flex justify-content-center" onClick={uploadImage}>
              <span>Upload Other </span>
              <Icon name="upload-cloud" />
            </Button>
          </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onCrop}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CropperComponent;
