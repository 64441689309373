import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Steps, Step } from "react-step-builder";
import GeneralInformation_Buyer from "./GeneralInformation_Buyer";
import LegalInformation_Buyer from "./LegalInformation_Buyer";
import ContactInformation_Buyer from "./ContactInformation_Buyer";
import Header_Buyer from "./Header_Buyer";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const config = {
  before: Header_Buyer as React.ComponentType<{}> as () => React.ReactElement,
};

const BuyerOnboard: React.FC = (props: any) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });

  return (
    <React.Fragment>
      <Head title="Regular Page v-1" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">
                Organisation Setup{" "}
                <span className="badge bg-info">Party Master</span>
              </BlockTitle>
              <BlockDes>
                Onboard suppliers and buyers to the procurement platform,
                collecting essential organizational, legal, contact, and banking
                information to create comprehensive profiles.
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="primary"
                className="btn-dim"
                onClick={() => {
                  navigate("/party-list");
                }}
              >
                <Icon name="menu-alt-r"></Icon>
                <span>List of registered parties</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <FormProvider {...formMethods}>
                <Steps config={config}>
                  <Step component={GeneralInformation_Buyer} />
                  <Step component={LegalInformation_Buyer}/>
                  <Step component={ContactInformation_Buyer} />
                </Steps>
              </FormProvider>
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default BuyerOnboard;
