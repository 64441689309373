import React, { useState, useEffect, useCallback } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import { useNavigate } from "react-router-dom";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import FormTextArea from "../form-components/TextAreaComponent";
import { useForm, FormProvider } from "react-hook-form";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import Swal from "sweetalert2";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import mastersServices from "../../api/masters/mastersService";
import { Spinner, Alert } from "reactstrap";
interface RowData {
  id?: string;
  uom_type: string;
  uom_name: string;
  uom_short_code: string;
  uom_description?: string;
  status?: string;
  createdDate?: string;
  modifiedDate?: string;
  serialNumber?: number; // Adding serial number to the data type
}

const UOMMaster: React.FC = (props) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, setValue, reset } = formMethods;

  const [modal, setModal] = useState<{ add: boolean; edit: boolean }>({
    add: false,
    edit: false,
  });
  const [currentRowData, setCurrentRowData] = useState<RowData | null>(null);

  const [loading, setLoading] = useState(false);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const submitForm = async (data: any) => {
    setLoading(true);
    if (modal.edit && currentRowData) {
      // Handle edit form submission
      console.log("Editing row:", currentRowData);
      console.log("Form data:", data);
      const upodateUOM = await mastersServices.updateUOM(
        data,
        currentRowData.id
      );
      const { status, message } = upodateUOM;
      if (status) {
        setLoading(false);
        Swal.fire("Updated!", "UOM has been updated", "success");
        setModal({ add: false, edit: false });
        reset();
        getUOMs();
      } else {
        setLoading(false);
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } else {
      // Handle add form submission
      console.log("Adding new row");
      console.log("Form data:", data);

      const addUOM = await mastersServices.addUOM(data);
      console.log(addUOM);
      const { status, message } = addUOM;
      if (status) {
        setValue("uom_type", "");
        setLoading(false);
        Swal.fire("Created!", "UOM has been created", "success");
        setModal({ add: false, edit: false });
        reset();
        getUOMs();
      } else {
        setLoading(false);
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    }
  };
  const [rowData, setRowData] = useState<RowData[]>([]);
  const getUOMs = useCallback(async () => {
    try {
      const uoms = await mastersServices.getUOMs();

      if (uoms.status && uoms.data.length > 0) {
        const dataWithSerialNumbers = uoms.data.map(
          (item: any, index: any) => ({
            ...item,
            serialNumber: index + 1,
          })
        );
        setRowData(dataWithSerialNumbers);
        //setRowData(uoms.data);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  const columns: TableColumn<RowData>[] = [
    {
      name: "S.No",
      width: "150px",
      selector: (row: RowData) => row.serialNumber!,
      sortable: false,
    },
    {
      name: "UOM Name",
      width: "150px",
      selector: (row: RowData) => row.uom_name,
      sortable: true,
    },
    {
      name: "Short Code",
      width: "130px",
      selector: (row: RowData) => row.uom_short_code,
      sortable: true,
    },
    {
      name: "UOM Type",
      width: "150px",
      selector: (row: RowData) => row.uom_type,
      sortable: true,
    },
    {
      name: "Description",
      width: "250px",
      selector: (row: RowData) => row.uom_description!,
      sortable: true,
      cell: (row: RowData) => (
        <div>
          <span
            id={`desc-tooltip-${row.id}`}
          >{`${row.uom_description!.substring(0, 20)}... `}</span>
          <TooltipComponent
            iconClass="info-circle text-info"
            icon="info"
            id={`desc-tooltip-${row.id}`}
            direction="top"
            text={row.uom_description}
            containerClassName={""}
          />
        </div>
      ),
    },
    {
      name: "Created Date",
      width: "250px",
      selector: (row: RowData) =>
        moment(row.createdDate).format("DD MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      width: "100px",
      selector: (row: RowData) => (row.status ? row.status : ""),
      sortable: true,
      cell: (row: RowData) => (
        <span
          className={`tb-status text-${
            row.status === "Active" ? "success" : "danger"
          }`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "",
      width: "90px",
      sortable: false,
      cell: (row: RowData) => (
        <DropdownTrans
          row={row}
          onEdit={() => handleEdit(row)}
          onRemove={() => handleRemove(row)}
        />
      ),
    },
  ];

  const handleEdit = (row: RowData) => {
    setCurrentRowData(row);
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
    setValue("uom_name", row.uom_name);
    setValue("uom_short_code", row.uom_short_code);
    setValue("uom_type", row.uom_type);
    setValue("uom_description", row.uom_description);
    setModal({ add: false, edit: true });
  };

  const handleRemove = async (row: RowData) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Make API call to remove the data
        try {
          const deleteUOM = await mastersServices.removeUOM(row.id);
          if (deleteUOM.status) {
            getUOMs();
            Swal.fire("Deleted!", "UOM has been removed.", "success");
          } else {
            Swal.fire({
              icon: "error",
              title: "Error in submission",
              text: "Please contact administrator",
              focusConfirm: false,
            });
          }
        } catch (error: any) {
          console.log(error.message);
        }
        console.log("Removing row:", row);
      }
    });
  };

  const onFormCancel = (e: any) => {
    e.preventDefault();
    setModal({ add: false, edit: false });
    reset();
  };
  useEffect(() => {
    getUOMs();
  }, []);

  return (
    <React.Fragment>
      <Head title="UOM Master" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">UOM Master</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button
                    color="primary"
                    className="btn-icon"
                    onClick={() => {
                      setAlertInfo({
                        type: "", // 'success' or 'error'
                        message: "",
                        errors: [],
                      });
                      setModal({ add: true, edit: false });
                    }}
                  >
                    <Icon name="plus"></Icon>
                    <span style={{marginLeft: "-18%"}}>Add </span>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <DataTable
              columns={columns}
              data={rowData}
              pagination
              striped
              dense
              className="custom-compact-rows"
            />
          </PreviewCard>
        </Block>
      </Content>
      <Modal
        isOpen={modal.add || modal.edit}
        toggle={() => setModal({ add: false, edit: false })}
        className="modal-dialog-centered"
        size="lg"
        backdrop="static"
      >
        <ModalBody>
          <a href="#cancel" onClick={onFormCancel} className="close">
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              {modal.edit ? "Edit Unit of Measure" : "Add Unit of Measure"}
            </h5>

            {alertInfo.message && (
              <div className="mb-3 mt-1">
                <Alert
                  color={alertInfo.type === "error" ? "danger" : "success"}
                  className="alert-icon"
                >
                  <strong>{alertInfo.message}</strong>
                  <Icon
                    name={
                      alertInfo.type === "error"
                        ? "alert-circle"
                        : "check-circle"
                    }
                  />
                  {alertInfo.errors.length > 0 ? (
                    <ul>
                      {alertInfo.errors.map((err, index) => (
                        <li key={index}>{err}</li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </Alert>
              </div>
            )}
            <div className="mt-4">
              <FormProvider {...formMethods}>
                <form
                  className="content clearfix"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <Row className="gy-4">
                    <Col lg="12">
                      <FormSelect
                        name="uom_type"
                        label="UOM Type"
                        options={[
                          { value: "primary", label: "Primary" },
                          { value: "secondary", label: "Secondary" },
                        ]}
                        required={true}
                        placeholder="-Select-"
                      />
                    </Col>
                    <Col lg="12">
                      <FormInput
                        name="uom_name"
                        label="UOM Name"
                        required={true}
                        type="text"
                        minLength={2}
                        maxLength={30}
                        placeholder="Enter UOM name"
                      />
                    </Col>
                    <Col lg="12">
                      <FormInput
                        name="uom_short_code"
                        label="Short Code"
                        required={true}
                        type="text"
                        minLength={1}
                        maxLength={10}
                        placeholder="Enter Short Code for UOM, e.g Kg"
                      />
                    </Col>
                    <Col lg="12">
                      <FormTextArea
                        name="uom_description"
                        label="Description"
                        required={true}
                        minLength={5}
                        maxLength={100}
                      />
                    </Col>
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="md" type="submit">
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={onFormCancel}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

interface DropdownTransProps {
  row: RowData;
  onEdit: () => void;
  onRemove: () => void;
}

const DropdownTrans: React.FC<DropdownTransProps> = ({
  row,
  onEdit,
  onRemove,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
      >
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul
          className="link-list-plain"
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onEdit();
              }}
            >
              <Icon name="icon ni ni-edit"></Icon>
              View/Edit
            </DropdownItem>
          </li>
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onRemove();
              }}
            >
              <Icon name="icon ni ni-na"></Icon>
              Remove
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UOMMaster;
