import React, { useState, useEffect, useCallback } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import { useNavigate } from "react-router-dom";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import FormTextArea from "../form-components/TextAreaComponent";
import { useForm, FormProvider } from "react-hook-form";
import { Col, Row, Modal, ModalBody } from "reactstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import Swal from "sweetalert2";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import mastersServices from "../../api/masters/mastersService";

interface RowData {
  referenceID: string;
  document_type_name: string;
  shortCode: string;
  document_type_description?: string;
  status: string;
  createdDate?: string;
  modifiedDate?: string;
  serialNumber?: number; // Adding serial number to the data type
  id?: string;
}

const DocumentTypeMaster: React.FC = (props) => {
  const navigate = useNavigate();
  const formMethods = useForm({
    mode: "onChange",
  });
  const { handleSubmit, setValue, reset } = formMethods;

  const [modal, setModal] = useState<{ add: boolean; edit: boolean }>({
    add: false,
    edit: false,
  });
  const [currentRowData, setCurrentRowData] = useState<RowData | null>(null);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [loading, setLoading] = useState(false);

  const submitForm = async (data: any) => {
    if (modal.edit && currentRowData) {
      // Handle edit form submission
      console.log("Editing row:", currentRowData);
      console.log("Form data:", data);
      // Make API call to update the data
      try {
        const addDocumentType = await mastersServices.updateDocumentType(
          data,
          currentRowData.id
        );
        setAlertInfo({
          type: addDocumentType.status ? "success" : "error",
          message: addDocumentType.message,
          errors: addDocumentType.status ? [] : addDocumentType.errors,
        });
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setAlertInfo({
          type: "error",
          message: error.message,
          errors: [],
        });
      }
    } else {
      // Handle add form submission
      console.log("Adding new row");
      console.log("Form data:", data);
      // Make API call to add the data

      setLoading(true);
      try {
        const addDocumentType = await mastersServices.addDocumentType(data);
        setAlertInfo({
          type: addDocumentType.status ? "success" : "error",
          message: addDocumentType.message,
          errors: addDocumentType.status ? [] : addDocumentType.errors,
        });
        if (addDocumentType.status) {
          reset();
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setAlertInfo({
          type: "error",
          message: error.message,
          errors: [],
        });
      }
    }
    setModal({ add: false, edit: false });
    getDocumentType();
  };


  const [rowData, setRowData] = useState<RowData[]>([]);

  const getDocumentType = useCallback(async () => {
    try {
      const documentType = await mastersServices.getDocumentType();

      if (documentType.status && documentType.data.length > 0) {
        // Add serial numbers to data
        const dataWithSerialNumbers = documentType.data.map(
          (item: any, index: any) => ({
            ...item,
            serialNumber: index + 1,
          })
        );
        setRowData(dataWithSerialNumbers);
      }
    } catch (error: any) {
      console.error("Failed to fetch admin material:", error.message);
    }
  }, []);

  useEffect(() => {
    // Add serial numbers to data
    getDocumentType();
  }, []);

  const columns: TableColumn<RowData>[] = [
    {
      name: "S.No",
      width: "100px",
      selector: (row: RowData) => row.serialNumber!,
      sortable: false,
    },
    {
      name: "Document Type",
      width: "250px",
      selector: (row: RowData) => row.document_type_name,
      sortable: true,
    },
    // {
    //     name: "Short Code",
    //     width: '100px',
    //     selector: (row: RowData) => row.shortCode,
    //     sortable: true,
    // },
    {
      name: "Description",
      width: "250px",
      selector: (row: RowData) => row.document_type_description!,
      sortable: true,
      cell: (row: RowData) => {
        const description = row.document_type_description || ''; // Default to empty string if null or undefined
        const truncatedDescription = description.length > 20 ? `${description.substring(0, 20)}...` : description;
    
        return (
          <div>
            <span id={`desc-tooltip-${row.referenceID}`}>{truncatedDescription}</span>
            <TooltipComponent
              iconClass="info-circle text-info"
              icon="info"
              id={`desc-tooltip-${row.referenceID}`}
              direction="top"
              text={description}
              containerClassName={""}
            />
          </div>
        );
      },
    },
    {
      name: "Created Date",
      width: "150px",
      selector: (row: RowData) =>
        moment(row.createdDate).format("DD MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Modified Date",
      width: "150px",
      selector: (row: RowData) =>
        moment(row.createdDate).format("DD MMMM YYYY"),
      sortable: true,
    },
    {
      name: "Status",
      width: "100px",
      selector: (row: RowData) => row.status,
      sortable: true,
      cell: (row: RowData) => (
        <span
          className={`tb-status text-${
            row.status === "Active" ? "success" : "danger"
          }`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "",
      width: "90px",
      sortable: false,
      cell: (row: RowData) => (
        <DropdownTrans
          row={row}
          onEdit={() => handleEdit(row)}
          onRemove={() => handleRemove(row)}
        />
      ),
    },
  ];

  const handleEdit = (row: RowData) => {
    console.log("row")
    setCurrentRowData(row);
    setValue("document_type_name", row.document_type_name);
    setValue("shortCode", row.shortCode);
    setValue("document_type_description", row.document_type_description);
    setModal({ add: false, edit: true });
  };

  const handleRemove = async(row: RowData) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async(result) => {
      if (result.isConfirmed) {
        // Make API call to remove the data
        try {
            const deleteUOM = await mastersServices.removeDocumentType(row.id)
            if (deleteUOM.status) {
                Swal.fire("Deleted!", "Document Type has been removed.");
                getDocumentType();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error in submission",
                    text: "Please contact administrator",
                    focusConfirm: false,
                });
            }

        } catch (error: any) {
            console.log(error.message)
        }
      }
    });
  };

  const onFormCancel = (e: any) => {
    e.preventDefault();
    setModal({ add: false, edit: false });
    reset();
  };

  return (
    <React.Fragment>
      <Head title="Document Type Master" />
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h4">Document Type Master</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button
                    color="primary"
                    className="btn-icon"
                    onClick={() => setModal({ add: true, edit: false })}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewCard className={"cardBox"} bodyClass="card-inner-xl">
            <DataTable
              columns={columns}
              data={rowData}
              pagination
              striped
              dense
              className="custom-compact-rows"
            />
          </PreviewCard>
        </Block>
      </Content>
      <Modal
        isOpen={modal.add || modal.edit}
        toggle={() => setModal({ add: false, edit: false })}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a href="#cancel" onClick={onFormCancel} className="close">
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">
              {modal.edit ? "Edit Document Type" : "Add Document Type"}
            </h5>
            <div className="mt-4">
              <FormProvider {...formMethods}>
                <form
                  className="content clearfix"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <Row className="gy-4">
                    <Col lg="12">
                      <FormInput
                        name="document_type_name"
                        label="Document Type"
                        required={true}
                        type="text"
                        minLength={2}
                        maxLength={30}
                        placeholder="Enter Document Type"
                      />
                    </Col>
                    <Col lg="12">
                      <FormInput
                        name="shortCode"
                        label="Short Code"
                        required={true}
                        type="text"
                        minLength={2}
                        maxLength={10}
                        placeholder="Enter Short Code"
                      />
                    </Col>
                    <Col lg="12">
                      <FormTextArea
                        name="document_type_description"
                        label="Description"
                        required={true}
                        minLength={5}
                        maxLength={100}
                      />
                    </Col>
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="md" type="submit">
                            Submit
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={onFormCancel}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

interface DropdownTransProps {
  row: RowData;
  onEdit: () => void;
  onRemove: () => void;
}

const DropdownTrans: React.FC<DropdownTransProps> = ({
  row,
  onEdit,
  onRemove,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="text-soft dropdown-toggle btn btn-icon btn-trigger"
      >
        <Icon name="more-h"></Icon>
      </DropdownToggle>
      <DropdownMenu end>
        <ul
          className="link-list-plain"
          style={{ paddingTop: 10, paddingBottom: 10 }}
        >
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onEdit();
              }}
            >
              <Icon name="icon ni ni-edit"></Icon>
              View/Edit
            </DropdownItem>
          </li>
          <li>
            <DropdownItem
              tag="a"
              href="#dropdownitem"
              onClick={(ev) => {
                ev.preventDefault();
                onRemove();
              }}
            >
              <Icon name="icon ni ni-na"></Icon>
              Remove
            </DropdownItem>
          </li>
        </ul>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DocumentTypeMaster;
